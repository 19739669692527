// App.tsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Submit from './Submit';
import Analytics from './Analytics';
import WhitePaper from './WhitePaper';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/submit" element={<Submit />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/about" element={<WhitePaper />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;