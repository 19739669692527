import React from 'react';

function WhitePaper() {
  return (
    <div className="page-container">
      <h1>White Paper</h1>
      {/* Add your white paper content here */}
    </div>
  );
}

export default WhitePaper;