// firebase.ts
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSagUlDu-nfmkvzH8K4xFmz0SL73vCFi8",
  authDomain: "virtuousai-9e55b.firebaseapp.com", 
  projectId: "virtuousai-9e55b",
  storageBucket: "virtuousai-9e55b.firebasestorage.app",
  messagingSenderId: "868834268810",
  appId: "1:868834268810:web:b9e1dd38bcd2377cbfff73",
  measurementId: "G-MCF6DWB1Z4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

// Create a default export object
const firebase = {
  app,
  db,
  analytics
};

export default firebase;