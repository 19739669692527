// Home.tsx
import { Link } from 'react-router-dom';
import './Home.css';
import logo from './assets/logo.png';

function Home() {
  return (
    <div className="content-container">
      <div className="header-section">
        <img src={logo} alt="VirtuousAI Logo" className="side-logo left-logo" />
        <div className="text-content">
          <h1 className="company-name">VirtuousAI</h1>
          <p className="tagline">dare to dream</p>
        </div>
        <img src={logo} alt="VirtuousAI Logo" className="side-logo right-logo" />
      </div>
      <div className="button-container">
        <Link to="/submit" className="nav-button">
          Submit Fraud Report
        </Link>
        <Link to="/analytics" className="nav-button">
          Read Fraud Analytics
        </Link>
        <Link to="/about" className="nav-button">
          White Paper
        </Link>
      </div>
    </div>
  );
}

export default Home;