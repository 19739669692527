// modal.tsx
import React from 'react';
import './modal.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Data Submission Agreement</h2>
          <button className="modal-close" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <p className="modal-text">By submitting data to Virtuous AI, you confirm that:</p>
          <ol className="modal-list">
            <li>
              Your submission contains no personal identifying information about any individuals, including but not limited to names, contact details, or financial information.
            </li>
            <li>
              You understand that submitted data will undergo automated privacy protection processing.
            </li>
            <li>
              You agree that the anonymized data may be used to enhance fraud prevention systems.
            </li>
            <li>
              You acknowledge that all submissions become the property of Virtuous AI and may be used for research and prevention purposes.
            </li>
          </ol>
          <p className="modal-text">Please ensure you have the right to share any information you submit. Your commitment to privacy helps us build better fraud prevention tools.</p>
        </div>
        <div className="modal-footer">
          <button className="modal-button submit-btn" onClick={onSubmit}>
            Acknowledge and Submit Report
          </button>
          <button className="modal-button cancel-btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;