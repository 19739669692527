import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  collection, 
  addDoc, 
  serverTimestamp,
} from 'firebase/firestore';
import { db } from './firebase';
import './Submit.css';
import Modal from './modal';

function Submit() {
  const [report, setReport] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [remainingChars, setRemainingChars] = useState<number>(5000);
  const [personalSubmissionsLeft, setPersonalSubmissionsLeft] = useState<number>(3);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const today = new Date().toDateString();
    const submissions = parseInt(localStorage.getItem(today) || '0');
    setPersonalSubmissionsLeft(3 - submissions);
  }, []);

  const handleSubmitClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!report.trim()) {
      setError('Please enter a report');
      return;
    }
    setShowConfirmModal(true);
  };

  const handleConfirmedSubmit = async () => {
    try {
      setIsSubmitting(true);
      await addDoc(collection(db, 'submissions'), {
        report: report.trim(),
        timestamp: serverTimestamp()
      });
      
      const today = new Date().toDateString();
      const submissions = parseInt(localStorage.getItem(today) || '0');
      const newSubmissionCount = submissions + 1;
      localStorage.setItem(today, newSubmissionCount.toString());
      setPersonalSubmissionsLeft(3 - newSubmissionCount);
      
      setReport('');
      setError('');
      setShowConfirmModal(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReportChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    if (text.length <= 5000) {
      setReport(text);
      setRemainingChars(5000 - text.length);
    }
  };

  return (
    <div className="submit-container">
      <div className="submit-content">
        <h1>Submit Fraud Report</h1>
        
        <div className="limits-info">
          <p className="personal-limit">
            Your Submissions Remaining Today: {personalSubmissionsLeft}/3
          </p>
        </div>

        <form onSubmit={handleSubmitClick} className="submit-form">
          <textarea
            value={report}
            onChange={handleReportChange}
            placeholder="Enter your fraud report here..."
            maxLength={5000}
            className="report-input"
          />
          
          <div className="char-counter">
            {remainingChars} characters remaining
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="button-stack">
            <button 
              type="submit" 
              className="submit-button"
              disabled={isSubmitting || personalSubmissionsLeft <= 0}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Report'}
            </button>
            
            <Link to="/" className="back-button">← Back</Link>
          </div>
        </form>
      </div>
      
      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onSubmit={handleConfirmedSubmit}
      />
    </div>
  );
}

export default Submit;