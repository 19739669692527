import React from 'react';

function Analytics() {
  return (
    <div className="page-container">
      <h1>Fraud Analytics</h1>
      {/* Add your analytics content here */}
    </div>
  );
}

export default Analytics;